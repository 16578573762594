import { Input } from 'antd';
import React, { useState } from 'react';
import DisplayPwIcon from '../Icons/DisplayPwIcon';
import HiddenPwIcon from '../Icons/HiddenPwIcon';
interface Props {
    idInput: string;
    value?: string;
    onChange?: (e: string) => void;
    name?: string;
}

const InputPassword = ({ idInput, value, onChange, name }: Props) => {
    const [isShow, setIsShow] = useState(false);
    const HideShowPassword = () => {
        setIsShow(!isShow);
        const inputElement: HTMLInputElement | null = document.getElementById(idInput) as HTMLInputElement;
        if (inputElement.type === 'password') {
            inputElement.type = 'text';
        } else {
            inputElement.type = 'password';
        }
    };
    return (
        <>
            <Input
                value={value}
                onChange={(e) => onChange && onChange(e.target.value)}
                className="input-text"
                id={idInput}
                type="password"
                name={name}
            />
            <div className="icon-password" onClick={() => HideShowPassword()}>
                {isShow ? <DisplayPwIcon /> : <HiddenPwIcon />}
            </div>
        </>
    );
};
export default InputPassword;
