/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Checkbox, Form, Input } from 'antd';
import React, { useState } from 'react';
import './register.scss';
import { loginSocialMedial, loginTwitter, registerAccount } from '../../../services/apiService';
import { toast } from '../../../utils/notification';
import { setCookie } from '../../../utils/cookie';
import FacebookLoginRender from 'react-facebook-login/dist/facebook-login-render-props';
import AppleLogin from 'react-apple-login';
import { getSubDomain } from '../../../components/common/function';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    FROM_MIRUMAR_APP,
    HTTPS,
    MESSAGE_ERROR_LOGIN_SOCIAL_MEDIA,
    MESSAGE_REGISTER_SUCCESS,
    MESSAGE_VALIDATE_EMAIL_REQUIRED,
    MESSAGE_VALIDATE_EMAIL_TYPE,
    MESSAGE_VALIDATE_LENGTH_PASSWORD,
    MESSAGE_VALIDATE_NAME_REQUIRED,
    MESSAGE_VALIDATE_NAME_TYPE,
    MESSAGE_VALIDATE_PASSWORD_REQUIRED,
    MESSAGE_VALIDATE_TYPE1_PASSWORD,
    MESSAGE_VALIDATE_TYPE2_PASSWORD,
    MESSAGE_VALIDATE_TYPE3_PASSWORD,
} from '../../../components/common/constant';
import InputPassword from '../common/InputPassword';
import { backToTopScroll } from '../common/FunctionCommon';
import { useGoogleLogin } from '@react-oauth/google';
import MyCustomGoogleButton from '../Login/MyCustomGoogleButton';
import { getCurrentOS } from '../../../utils/os';
import { OperatingSystem } from '../../../ts/enums';
const Register: React.FC = () => {
    const [form] = Form.useForm();
    const [loadingRegister, setLoadingRegister] = useState<boolean>(false);
    const [isAgree, setIsAgree] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const navigateUrl = (path: string) => {
        navigate(path + location.search);
    };
    const params = new URLSearchParams(location.search);
    const isFromMirumar = params.get('service') === FROM_MIRUMAR_APP;
    const urlRedirect = params.get('url');
    const isAndroid = getCurrentOS() === OperatingSystem.Android;
    const isIOS = getCurrentOS() === OperatingSystem.iOS;

    const setCookieData = (dataLogin: any) => {
        setCookie('refresh_token', dataLogin?.refresh_token, 7, '');
        const expirationTime = new Date().getTime() + 7200 * 1000;
        setCookie('access_token_expiration_time', expirationTime, 2 / 24, String(process.env.REACT_APP_HACOSCO_WEB_HOME_URL));
        setCookie('id_token', dataLogin?.id_token, 2 / 24, String(process.env.REACT_APP_HACOSCO_WEB_HOME_URL));
        setCookie('refresh_token', dataLogin?.refresh_token, 7, getSubDomain());
        setCookie('access_token', dataLogin?.access_token, 2 / 24, String(process.env.REACT_APP_HACOSCO_WEB_HOME_URL));
    };
    const handleSubmitRegister = async () => {
        if (!isAgree) {
            return;
        }
        setLoadingRegister(true);
        let param: any = {
            username: form.getFieldValue('name'),
            email: form.getFieldValue('email'),
            password: form.getFieldValue('password'),
            password_confirmation: form.getFieldValue('confirmPassword'),
            grant_type: 'sign_up',
        };
        if (isFromMirumar) {
            param = { ...param, service: FROM_MIRUMAR_APP };
        }
        const registerData: any = await registerAccount(param);
        if (registerData?.statusCode === 200) {
            setErrorMessage('');
            toast('success', MESSAGE_REGISTER_SUCCESS);
            backToTopScroll();
            navigateUrl('/');
        } else if (registerData?.statusCode === 400) {
            setErrorMessage(registerData?.error);
        }
        setLoadingRegister(false);
    };

    const responseFacebook = async (response: any) => {
        if (response && response?.status !== 'unknown') {
            const payload = {
                access_token: response?.accessToken,
                provider: 'facebook',
            };
            setLoadingRegister(true);
            const responseLoginFacebook = await loginSocialMedial(payload, isFromMirumar);
            setLoadingRegister(false);

            if (!isFromMirumar) {
                if (responseLoginFacebook.data) {
                    setCookieData(responseLoginFacebook?.data);
                    window.location.href = String(process.env.REACT_APP_HACOSCO_WEB_HOME_URL);
                } else {
                    toast('error', MESSAGE_ERROR_LOGIN_SOCIAL_MEDIA);
                }
            } else {
                if (responseLoginFacebook?.redirect_url) {
                    window.location.href = `${HTTPS + urlRedirect + `?code=${responseLoginFacebook?.code}`}`;
                } else {
                    toast('error', MESSAGE_ERROR_LOGIN_SOCIAL_MEDIA);
                }
            }
        }
    };

    const responseApple = async (response: any) => {
        if (response && !response?.error) {
            const payload = {
                access_token: response?.authorization?.id_token,
                provider: 'apple',
            };
            setLoadingRegister(true);
            const responseLoginApple = await loginSocialMedial(payload, isFromMirumar);
            setLoadingRegister(false);

            if (!isFromMirumar) {
                if (responseLoginApple?.data) {
                    setCookieData(responseLoginApple?.data);
                    window.location.href = String(process.env.REACT_APP_HACOSCO_WEB_HOME_URL);
                } else {
                    toast('error', MESSAGE_ERROR_LOGIN_SOCIAL_MEDIA);
                }
            } else {
                if (responseLoginApple?.redirect_url) {
                    window.location.href = `${HTTPS + urlRedirect + `?code=${responseLoginApple?.code}`}`;
                } else {
                    toast('error', MESSAGE_ERROR_LOGIN_SOCIAL_MEDIA);
                }
            }
        }
    };
    const handleLoginTwitter = async () => {
        const response = await loginTwitter();
        await localStorage.setItem('access_token_secret', response?.data?.oauth_token_secret);
        localStorage.setItem('service_from', isFromMirumar ? 'mirumar' : '');
        localStorage.setItem('url_redirect', urlRedirect ?? '');

        if (response?.data?.link_login) {
            window.location.href = response?.data?.link_login;
        }
    };

    const registerGoogle = useGoogleLogin({
        onSuccess: async (codeResponse: any) => {
            if (codeResponse) {
                const payload = {
                    access_token: codeResponse?.access_token,
                    provider: 'google',
                };
                setLoadingRegister(true);
                const responseLoginGoogle = await loginSocialMedial(payload, isFromMirumar);
                setLoadingRegister(false);
                if (!isFromMirumar) {
                    if (responseLoginGoogle?.data) {
                        setCookieData(responseLoginGoogle?.data);
                        window.location.href = String(process.env.REACT_APP_HACOSCO_WEB_HOME_URL);
                    } else {
                        toast('error', MESSAGE_ERROR_LOGIN_SOCIAL_MEDIA);
                    }
                } else {
                    if (responseLoginGoogle?.redirect_url) {
                        window.location.href = `${HTTPS + urlRedirect + `?code=${responseLoginGoogle?.code}`}`;
                    } else {
                        toast('error', MESSAGE_ERROR_LOGIN_SOCIAL_MEDIA);
                    }
                }
            }
        },
        onError: (error) => {
            //
        },
    });

    const shouldHideSNSButtons = isFromMirumar;

    const renderSNSLoginButtons = !shouldHideSNSButtons && (
        <div className="flex justify-center gap-[48px]">
            <button
                onClick={() => handleLoginTwitter()}
                className="flex justify-center items-center gap-[5px] text-[#1890FF] !bg-[unset] !border-0 !p-0">
                <img src="/image/icTwitter.svg" />
            </button>
            <FacebookLoginRender
                disableMobileRedirect={true}
                autoLoad={false}
                appId={String(process.env.REACT_APP_FACEBOOK_LOGIN_ID)}
                fields="name,email,picture"
                cssClass="login__btn"
                callback={responseFacebook}
                render={(renderProps) => (
                    <button
                        onClick={renderProps.onClick}
                        className="flex justify-center items-center gap-[5px] text-[#1890FF] !bg-[unset] !border-0 !p-0">
                        <img src="/image/icFacebook.svg" />
                    </button>
                )}
            />
            <AppleLogin
                callback={responseApple}
                clientId={String(process.env.REACT_APP_APPLE_LOGIN_CLIENT_ID_URL)}
                redirectURI={String(process.env.REACT_APP_APPLE_LOGIN_REDIRECT_URL)}
                scope="name email"
                state={'state'}
                nonce={'nonce'}
                responseType={'id_token'}
                responseMode={'form_post'}
                usePopup={true}
                render={(renderProps) => (
                    <button onClick={renderProps.onClick} className="flex justify-center items-center gap-[5px] !bg-[unset] !border-0 !p-0">
                        <img src="/image/icApple.svg" />
                    </button>
                )}
            />
            <MyCustomGoogleButton login={registerGoogle} />
        </div>
    );

    return (
        <div className="layout-container">
            <div className="flex register-container">
                <div className="w-[50%] flex justify-center items-center max-[840px]:hidden">
                    <img src="/image/loginImg.svg" alt="banner" className="object-cover flex justify-center items-center" />
                </div>
                <div className="w-[50%] py-[30px] max-[840px]:w-full flex items-center justify-center bg-[#fafafa]">
                    <div className="w-[318px]">
                        <div className="font-medium text-[18px] leading-[20px] text-center text-[#222]">ハコスコIDの登録</div>
                        <div className="mt-[24px] mb-[32px] text-color">
                            <p>ハコスコIDは、ハコスコが提供する複数サービス</p>
                            <p>を一つの共通IDで利用できる仕組みです。</p>
                        </div>
                        <Form
                            onValuesChange={() => setErrorMessage('')}
                            onFinishFailed={() => setErrorMessage('')}
                            onFinish={handleSubmitRegister}
                            className=""
                            name="trigger"
                            style={{ maxWidth: 600 }}
                            layout="vertical"
                            autoComplete="off"
                            form={form}>
                            <Form.Item
                                htmlFor="name"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || (/^[a-zA-Z0-9]+$/.test(value) && value.length > 3 && value.length < 21)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(MESSAGE_VALIDATE_NAME_TYPE));
                                        },
                                    }),
                                    {
                                        required: true,
                                        message: MESSAGE_VALIDATE_NAME_REQUIRED,
                                    },
                                ]}
                                label="ユーザID（半角英数字：4～20文字）"
                                name="name">
                                <Input name="name" id="name" className="input-text" />
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                                    value.toLowerCase(),
                                                ) &&
                                                    value.length < 225)
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(MESSAGE_VALIDATE_EMAIL_TYPE));
                                        },
                                    }),
                                    {
                                        required: true,
                                        message: MESSAGE_VALIDATE_EMAIL_REQUIRED,
                                    },
                                ]}
                                label={
                                    <div>
                                        <p>
                                            メールアドレス（キャリアメール非対応：
                                            <span
                                                onClick={() =>
                                                    (window.location.href = String(
                                                        process.env.REACT_APP_HACOSCO_SERVER_PAGE_EMAIL_DESCRIPTION,
                                                    ))
                                                }
                                                className="!text-[#00A5ED] underline cursor-pointer">
                                                詳細
                                            </span>
                                            {')'}
                                        </p>
                                    </div>
                                }
                                htmlFor="email"
                                name="email">
                                <Input name="email" id="email" className="input-text" />
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value) {
                                                if (value.length < 8 || value.length > 20) {
                                                    return Promise.reject(new Error(MESSAGE_VALIDATE_LENGTH_PASSWORD));
                                                } else if (!/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value)) {
                                                    return Promise.reject(new Error(MESSAGE_VALIDATE_TYPE1_PASSWORD));
                                                } else if (!/^(?=.*[0-9])(?=.*[(!@#$%^&*)]).*$/.test(value)) {
                                                    return Promise.reject(new Error(MESSAGE_VALIDATE_TYPE2_PASSWORD));
                                                } else if (!/^[a-zA-Z0-9(!@#$%^&*)]+$/.test(value)) {
                                                    return Promise.reject(new Error(MESSAGE_VALIDATE_TYPE3_PASSWORD));
                                                }
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                    {
                                        required: true,
                                        message: MESSAGE_VALIDATE_PASSWORD_REQUIRED,
                                    },
                                ]}
                                label="パスワード（半角英数特殊文字：8～20文字）"
                                name="password"
                                htmlFor="password"
                                className="!mb-[48px]">
                                <InputPassword name="password" idInput="password" />
                            </Form.Item>

                            <Form.Item name="checkboxConfirm" valuePropName="checked" noStyle>
                                <div className="flex justify-between items-start check-agree">
                                    <Checkbox onChange={(e) => setIsAgree(e.target.checked)} className="w-[24px] h-[24px]" />
                                    <div className="text-color">
                                        <span>
                                            <span
                                                onClick={() =>
                                                    (window.location.href = String(
                                                        process.env.REACT_APP_HACOSCO_SERVER_PAGE_TERMS_OF_SERVICE,
                                                    ))
                                                }
                                                className="!text-[#00A5ED] underline cursor-pointer">
                                                利用規約
                                            </span>
                                        </span>
                                        と
                                        <span>
                                            {' '}
                                            <span
                                                onClick={() =>
                                                    (window.location.href = String(process.env.REACT_APP_HACOSCO_SERVER_PAGE_PRIVACY))
                                                }
                                                className="!text-[#00A5ED] underline cursor-pointer">
                                                プライバシーポリシー
                                            </span>
                                        </span>
                                        に同意しま
                                        <p>す。（17歳未満の方はご利用いただけません）</p>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item className="mb-[32px] mt-[44px]" shouldUpdate>
                                {() => (
                                    <>
                                        {errorMessage && (
                                            <div className="text-[#F00] text-center text-[12px] font-medium mb-[8px]">{errorMessage}</div>
                                        )}
                                        <Button
                                            disabled={
                                                !isAgree ||
                                                form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                                                !form.isFieldsTouched(true) ||
                                                !!errorMessage
                                            }
                                            type="primary"
                                            loading={loadingRegister}
                                            htmlType="submit"
                                            className="block h-[48px] !text-[white] w-full text-[16px] font-medium rounded-[5px] !bg-[#E25252]">
                                            <span>登</span>
                                            <span>録</span>
                                        </Button>
                                    </>
                                )}
                            </Form.Item>
                            {!shouldHideSNSButtons && (
                                <div className="text-center my-[18px] text-[#666] flex justify-between items-center">
                                    <p className="h-[1px] w-[22%] m-0 bg-[#E7E7E7]"></p>外部アカウントでログイン
                                    <p className="h-[1px] w-[22%] m-0 bg-[#E7E7E7]"></p>
                                </div>
                            )}
                        </Form>
                        {renderSNSLoginButtons}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Register;
