import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <GoogleOAuthProvider clientId={String(process.env.REACT_APP_CLIENT_ID_LOGIN_GOOGLE)}>
        <App />
    </GoogleOAuthProvider>,
);
