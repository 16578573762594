export const getSubDomain = () => {
    const currentPath = window.location.origin;
    const withoutProtocol = currentPath.replace(/(^\w+:|^)\/\//, '');
    const parts = withoutProtocol.split('.');
    if (parts.length <= 2) {
        return '';
    }

    return '.' + parts[parts.length - 2] + '.' + parts[parts.length - 1];
};
