/* eslint-disable react/react-in-jsx-scope */
import { Suspense, useEffect } from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { publicRoutes } from './routes';

function App() {
    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            const metaTag = document.getElementById('meta-tag');
            if (metaTag) {
                metaTag.setAttribute('content', 'nofollow,noindex,notranslate');
            }
        }
    }, []);
    return (
        <>
            <Router>
                <Suspense>
                    <Routes>
                        {publicRoutes.map((route, index) => {
                            const Page = route.component;
                            return <Route key={index} path={route.path} element={<Page />} />;
                        })}
                    </Routes>
                </Suspense>
            </Router>
        </>
    );
}

export default App;
