import React from 'react';
const HiddenPwIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 0H0V24H24V0Z" fill="transparent" />
            <path
                d="M4.75202 4.09707C4.25116 3.66306 3.4933 3.71725 3.05928 4.21811C2.62527 4.71897 2.67946 5.47684 3.18032 5.91085L19.273 19.8557C19.7739 20.2897 20.5318 20.2355 20.9658 19.7347C21.3998 19.2338 21.3456 18.4759 20.8447 18.0419L4.75202 4.09707Z"
                fill="#212121"
            />
            <path
                d="M13.0816 16.0731L15.5116 18.1791C14.4556 18.6471 13.2856 18.9471 12.0076 18.9471C6.16356 18.9471 2.50956 12.7071 2.35956 12.4431L2.10156 11.9931L2.35956 11.5431C2.42556 11.4231 3.19956 10.1031 4.56156 8.68706L7.81956 11.5071C7.80156 11.6691 7.78956 11.8251 7.78956 11.9931C7.78956 14.3271 9.67956 16.2171 12.0136 16.2171C12.3856 16.2171 12.7456 16.1631 13.0876 16.0731H13.0816ZM21.6556 11.5431C21.5056 11.2791 17.8456 5.03906 12.0076 5.03906C10.7416 5.03906 9.57156 5.33306 8.52756 5.79506L10.9636 7.90106C11.2996 7.81706 11.6476 7.76306 12.0136 7.76306C14.3476 7.76306 16.2376 9.65306 16.2376 11.9871C16.2376 12.1431 16.2256 12.2931 16.2136 12.4431L19.4836 15.2751C20.8336 13.8591 21.6016 12.5511 21.6676 12.4311L21.9256 11.9811L21.6676 11.5311L21.6556 11.5431ZM12.0076 10.0911C10.9576 10.0911 10.1056 10.9431 10.1056 11.9931C10.1056 13.0431 10.9576 13.8951 12.0076 13.8951C13.0576 13.8951 13.9096 13.0431 13.9096 11.9931C13.9096 10.9431 13.0576 10.0911 12.0076 10.0911Z"
                fill="#212121"
            />
        </svg>
    );
};
export default HiddenPwIcon;
