// Add data types to window.navigator for use in this file. See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html#-reference-types- for more info.
/// <reference types="user-agent-data-types" />

import { OperatingSystem } from '../ts/enums';

export function getCurrentOS() {
    const userAgent = window.navigator.userAgent,
        platform =
            window.navigator.userAgentData?.platform ||
            window.navigator.platform,
        macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        // os = 'Mac OS';
        os = OperatingSystem.MacOS;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        // os = 'iOS';
        os = OperatingSystem.iOS;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        // os = 'Windows';
        os = OperatingSystem.Windows;
    } else if (/Android/.test(userAgent)) {
        // os = 'Android';
        os = OperatingSystem.Android;
    } else if (/Linux/.test(platform)) {
        // os = 'Linux';
        os = OperatingSystem.Linux;
    }

    return os;
}
