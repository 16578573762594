/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { set } from 'lodash';
import { toast } from '../utils/notification';
import { getCookie, setCookie } from '../utils/cookie';
import { refreshAccessToken } from './apiService';

const DEFAULT_TIMEOUT = 10000;
const Axios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: DEFAULT_TIMEOUT,
});

Axios.interceptors.request.use((request) => {
    const accessToken: string | undefined = getCookie('access_token') || getCookie('accessToken');
    const expirationTime = getCookie('access_token_expiration_time');
    const refreshAccessTokenData = async () => {
        try {
            const dataResponse: any = await refreshAccessToken({
                refresh_token: getCookie('refresh_token'),
                grant_type: 'refresh_token',
            });
            if (dataResponse?.body) {
                const expirationTime = new Date().getTime() + 7200 * 1000;
                setCookie('access_token_expiration_time', expirationTime, 2 / 24, '.codeaplha.biz');
                setCookie('access_token', dataResponse?.body?.access_token, 2 / 24, '.codeaplha.biz');
                setCookie('id_token', dataResponse?.body?.id_token, 2 / 24, '.codeaplha.biz');
            }
        } catch (error) {
            console.error('Error during token refresh', error);
        }
    };
    if (accessToken && expirationTime && new Date().getTime() > parseInt(expirationTime)) {
        refreshAccessTokenData();
    }
    if (!accessToken) {
        return request;
    }
    set(request, 'headers.Authorization', `Bearer ${accessToken}`);
    return request;
});

Axios.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            return response.data;
        }
        return response;
    },
    async (error) => {
        const errorResponse = error.response;
        if (errorResponse && errorResponse.status === 401) {
            localStorage.clear();
            window.location.replace('/');
        }
        if (errorResponse && errorResponse.status === 500) {
            toast('error', '内部サーバーエラーが発生しました。');
        }
        Promise.reject(error).catch((error) => error);
        throw errorResponse;
    },
);

export default Axios;
