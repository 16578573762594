/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { DELETE_ACCOUNT, FORGOT_PASSWORD, LOGIN, REDIRECT_TOKEN, REFRESH_TOKEN, SIGN_UP, UPDATE_PASSWORD } from './apiConstant';
import Axios from './baseApiService';
import { responseDeleteAcc } from '../layout/UnAuthentication/DeleteAccount';
import { FROM_MIRUMAR_APP } from '../components/common/constant';

export const loginLambda = async (query: object) => {
    try {
        const data = await Axios.post(LOGIN, query);
        return data;
    } catch (error) {
        return;
    }
};
export const redirectTokenMetastore = async (idToken: string) => {
    try {
        const data = await axios.post(process.env.REACT_APP_METASTORE_WEB_HOME_URL + REDIRECT_TOKEN, null, {
            headers: { Authorization: idToken },
        });
        return data;
    } catch (error) {
        return;
    }
};
export const refreshAccessToken = async (payload: any) => {
    try {
        const data = await Axios.post(REFRESH_TOKEN, payload);
        return data;
    } catch (error) {
        return;
    }
};

export const loginSocialMedial = async (dataRequest: any, isFromMirumar: boolean) => {
    try {
        let payload = { ...dataRequest, grant_type: 'login_sns' };
        if (isFromMirumar) {
            payload = { ...payload, service: FROM_MIRUMAR_APP };
        }
        const data = await axios.post(`${process.env.REACT_APP_API_URL}login`, payload, {
            headers: { Accept: 'application/json' },
        });
        return data.data;
    } catch (error) {
        return;
    }
};

export const loginTwitter = async () => {
    try {
        const data = await axios.post(
            `${process.env.REACT_APP_API_URL}access_twitter`,
            { grant_type: 'access_twitter' },
            {
                headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
            },
        );
        return data?.data;
    } catch (error) {
        return;
    }
};

export const registerAccount = async (requestData: any) => {
    try {
        const payload = requestData;
        const data = await Axios.post(SIGN_UP, payload);
        return data;
    } catch (error) {
        return;
    }
};

export const sendMailForgotPw = async (email: string, isFromMirumar: boolean) => {
    try {
        let payload: any = { email, grant_type: 'forgot_password' };
        if (isFromMirumar) {
            payload = { ...payload, service: FROM_MIRUMAR_APP };
        }
        const data = await Axios.post(FORGOT_PASSWORD, payload);
        return data;
    } catch (error) {
        return;
    }
};

export const resetPassword = async (requestData: any) => {
    try {
        const data = await Axios.post(UPDATE_PASSWORD, requestData);
        return data;
    } catch (error) {
        return;
    }
};

export const deleteAccount = async (requestData: any) => {
    try {
        const payload = { ...requestData, grant_type: 'destroy' };
        const data: responseDeleteAcc = await Axios.post(DELETE_ACCOUNT, payload);
        return data;
    } catch (error) {
        return;
    }
};

