import { notification } from 'antd';
type type = 'success' | 'info' | 'warning' | 'error';
export const toast = (
    type: type = 'info',
    message = 'INFO',
    description = '',
    className = 'popup-message',
) => {
    notification.open({
        type,
        message,
        description,
        className,
    });
};
