import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { deleteAccount } from '../../../services/apiService';
import { HTTPS, MESSAGE_DELETE_ACCOUNT_FAILED } from '../../../components/common/constant';

export type responseDeleteAcc = {
    statusCode: number;
    data: {
        success: boolean;
        redirect_url: string;
    };
};
const DeleteAccount = () => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const params = new URLSearchParams(document.location.search);
    const access_token = params.get('token');
    const service = params.get('service');
    const urlRedirect = params.get('url') ?? '';
    const [errorMessage, setErrorMessage] = useState('');

    const handleDelete = async () => {
        if (access_token) {
            setLoading(true);
            const deleteAcc = await deleteAccount({ access_token, service: service });
            setLoading(false);
            if (deleteAcc?.statusCode === 200) {
                setStatus(true);
                setErrorMessage('');
            } else {
                setErrorMessage(MESSAGE_DELETE_ACCOUNT_FAILED);
            }
            setIsOpen(false);
        } else return;
    };

    return (
        <div className="layout-container">
            <div className="flex register-container !h-[100vh]">
                <div className="w-[50%] flex justify-center items-center max-[840px]:hidden">
                    <img src="/image/loginImg.svg" alt="banner" className="object-cover flex justify-center items-center" />
                </div>
                <div className="w-[50%] max-[840px]:w-full flex items-center justify-center bg-[#fafafa]">
                    <div className="w-[350px] text-center">
                        {!status ? (
                            <>
                                <div
                                    className={`font-medium text-[18px] leading-[20px] text-center text-[#222] ${
                                        !errorMessage ? 'mb-[32px]' : 'mb-[16px]'
                                    }`}>
                                    ミルマルアカウントの削除
                                </div>
                                {errorMessage && (
                                    <div className="text-[#F00] text-center text-[12px] font-medium mb-[4px]">{errorMessage}</div>
                                )}
                                <Button
                                    type="primary"
                                    onClick={() => setIsOpen(true)}
                                    className="mx-auto block max-w-[318px] h-[48px] !text-[white] w-full text-[16px] font-medium rounded-[5px] !bg-[#E25252] mb-[24px]">
                                    <span>削</span>除
                                </Button>
                            </>
                        ) : (
                            <div className="text-center font-medium">
                                <div className="text-[18px] leading-[20px] text-[#222]">アカウントを削除しました</div>
                                <div className="text-[14px] text-[#000000] mt-[30px] mb-[40px]">
                                    ハコスコ統合サービスには引き続きログイン可能です。
                                </div>
                            </div>
                        )}
                        <Button
                            onClick={() => {
                                if (status) {
                                    // delete success
                                    window.location.href = HTTPS + urlRedirect + '?code=delete';
                                } else {
                                    if (errorMessage) {
                                        //delete failed
                                        window.location.href = HTTPS + urlRedirect + '?code=delete_failed';
                                    } else {
                                        // not delete and back
                                        window.location.href = HTTPS + urlRedirect;
                                    }
                                }
                                return;
                            }}
                            style={{ border: '2px solid #E25252' }}
                            className="max-w-[318px] h-[48px] !text-[#E25252] w-full text-[16px] font-medium rounded-[5px] !bg-[white]">
                            戻る
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                className="modal-delete"
                centered
                open={isOpen}
                footer={null}
                onCancel={() => setIsOpen(false)}
                closable={false}
                width={429}>
                <div className="min-h-[50px] text-[16px] font-medium text-[#000000] text-center whitespace-pre-wrap">
                    <div className="max-[450px]:hidden">本当にアカウントを削除してもよろしいですか？</div>
                    <div className="min-[450px]:hidden">
                        本当にアカウントを削除しても<div>よろしいですか？</div>
                    </div>
                    <div>この操作は取り消せません。</div>
                </div>

                <div className="flex items-center w-full justify-center mt-[32px]">
                    <Button
                        onClick={() => setIsOpen(false)}
                        className="bg-white font-bold !text-[#E25252] !border-[#E25252] text-[14px] w-[15rem] h-[48px] flex items-center justify-center">
                        キャンセル
                    </Button>
                    <Button
                        disabled={loading}
                        loading={loading}
                        className="block !bg-[#E25252] font-bold hover:!border-[#E25252]  hover:!text-white ml-[1.7rem] text-white border-[#E25252] text-[14px] leading-[2.5rem] w-[15rem] h-[48px]"
                        onClick={() => handleDelete()}>
                        <span>削</span>除
                    </Button>
                </div>
            </Modal>
        </div>
    );
};
export default DeleteAccount;
