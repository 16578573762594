import React from 'react';
const DisplayPwIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 0H0V24H24V0Z" fill="transparent" />
            <path
                d="M21.6556 11.5431C21.5056 11.2791 17.8456 5.03906 12.0076 5.03906C6.16956 5.03906 2.50956 11.2791 2.35956 11.5431L2.10156 11.9931L2.35956 12.4431C2.50956 12.7071 6.16956 18.9471 12.0076 18.9471C17.8456 18.9471 21.5056 12.7071 21.6556 12.4431L21.9136 11.9931L21.6556 11.5431ZM12.0076 17.1471C8.02956 17.1471 5.10156 13.3251 4.20756 11.9931C5.10756 10.6611 8.02956 6.83906 12.0076 6.83906C15.9856 6.83906 18.9136 10.6611 19.8076 11.9931C18.9076 13.3251 15.9856 17.1471 12.0076 17.1471ZM12.0076 8.39306C10.0216 8.39306 8.40756 10.0071 8.40756 11.9931C8.40756 13.9791 10.0216 15.5931 12.0076 15.5931C13.9936 15.5931 15.6076 13.9791 15.6076 11.9931C15.6076 10.0071 13.9936 8.39306 12.0076 8.39306ZM12.0076 13.7931C11.0176 13.7931 10.2076 12.9831 10.2076 11.9931C10.2076 11.0031 11.0176 10.1931 12.0076 10.1931C12.9976 10.1931 13.8076 11.0031 13.8076 11.9931C13.8076 12.9831 12.9976 13.7931 12.0076 13.7931Z"
                fill="#212121"
            />
        </svg>
    );
};
export default DisplayPwIcon;
