/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { sendMailForgotPw } from '../../../services/apiService';
import { toast } from '../../../utils/notification';
import {
    FROM_MIRUMAR_APP,
    MESSAGE_SENDMAIL_FAILED,
    MESSAGE_SENDMAIL_SUCCESS,
    MESSAGE_VALIDATE_EMAIL_REQUIRED,
    MESSAGE_VALIDATE_EMAIL_TYPE,
} from '../../../components/common/constant';
import { backToTopScroll } from '../common/FunctionCommon';
import { useLocation } from 'react-router-dom';

const ForgotPassWord = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isFromMirumar = params.get('service') === FROM_MIRUMAR_APP;
    const handleSubmitForgotPw = async () => {
        const notiPopup = document.querySelector('.popup-message');
        if (notiPopup) {
            return;
        }
        setLoading(true);
        const email = form.getFieldValue('email');
        const response: any = await sendMailForgotPw(email, isFromMirumar);
        if (response?.statusCode === 200) {
            localStorage.setItem('email', email);
            setErrorMessage('');
            toast('success', MESSAGE_SENDMAIL_SUCCESS);
            backToTopScroll();
        } else {
            setErrorMessage(response?.error);
        }
        setLoading(false);
    };

    useEffect(() => {
        localStorage.setItem('url_app_Mirumar', params.get('url') ?? '');
    }, []);

    return (
        <div className="layout-container">
            <div className="flex register-container !h-[100vh]">
                <div className="w-[50%] flex justify-center items-center max-[840px]:hidden">
                    <img src="/image/loginImg.svg" alt="banner" className="object-cover flex justify-center items-center" />
                </div>
                <div className="w-[50%] max-[840px]:w-full flex items-center justify-center bg-[#fafafa]">
                    <div className="w-[318px]">
                        <div className="font-medium text-[18px] leading-[20px] text-center text-[#222]">パスワードのリセット</div>
                        <div className="mt-[24px] mb-[32px] text-color">
                            <p>登録済みのメールアドレス宛に、パスワードのリ</p>
                            <p>セットについてのメールを送信します。</p>
                        </div>
                        <Form
                            onValuesChange={() => setErrorMessage('')}
                            onFinishFailed={() => setErrorMessage('')}
                            onFinish={handleSubmitForgotPw}
                            className=""
                            name="trigger"
                            style={{ maxWidth: 600 }}
                            layout="vertical"
                            autoComplete="off"
                            form={form}>
                            <Form.Item
                                rules={[
                                    {
                                        type: 'email',
                                        message: MESSAGE_VALIDATE_EMAIL_TYPE,
                                    },
                                    {
                                        required: true,
                                        message: MESSAGE_VALIDATE_EMAIL_REQUIRED,
                                    },
                                ]}
                                htmlFor="email"
                                label="メールアドレス"
                                name="email">
                                <Input name="email" id="email" className="input-text" />
                            </Form.Item>

                            <Form.Item className="mt-[32px]" shouldUpdate>
                                {() => (
                                    <>
                                        {errorMessage && (
                                            <div className="text-[#F00] text-center text-[12px] font-medium mb-[8px]">{errorMessage}</div>
                                        )}
                                        <Button
                                            disabled={
                                                form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !!errorMessage
                                            }
                                            type="primary"
                                            loading={loading}
                                            htmlType="submit"
                                            className="block h-[48px] !text-[white] w-full text-[16px] font-medium rounded-[5px] !bg-[#E25252]">
                                            <span>送</span>
                                            <span>信</span>
                                        </Button>
                                    </>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ForgotPassWord;
