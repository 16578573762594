/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { loginSocialMedial } from '../../../services/apiService';
import { setCookie } from '../../../utils/cookie';
import { getSubDomain } from '../../../components/common/function';
import { FROM_MIRUMAR_APP, HTTPS, MESSAGE_ERROR_LOGIN_SOCIAL_MEDIA } from '../../../components/common/constant';
import { toast } from '../../../utils/notification';
import { useNavigate } from 'react-router-dom';

const AuthLogin: React.FC = () => {
    const navigate = useNavigate();
    const setCookieData = (dataLogin: any) => {
        const expirationTime = new Date().getTime() + 7200 * 1000;
        setCookie('access_token_expiration_time', expirationTime, 2 / 24, String(process.env.REACT_APP_HACOSCO_WEB_HOME_URL));
        setCookie('id_token', dataLogin?.id_token, 2 / 24, String(process.env.REACT_APP_HACOSCO_WEB_HOME_URL));
        setCookie('refresh_token', dataLogin?.refresh_token, 7, getSubDomain());
        setCookie('access_token', dataLogin?.access_token, 2 / 24, String(process.env.REACT_APP_HACOSCO_WEB_HOME_URL));
    };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const oauth_token = urlParams.get('oauth_token');
        const oauth_verifier = urlParams.get('oauth_verifier');
        const isMirumar = localStorage.getItem('service_from') === FROM_MIRUMAR_APP;
        const urlRedirect = localStorage.getItem('url_redirect');

        const handleFinishLoginTwitter = async () => {
            const payload: any = {
                access_token: oauth_token,
                oauth_verifier: oauth_verifier,
                access_token_secret: localStorage.getItem('access_token_secret'),
                provider: 'twitter',
            };
            const response = await loginSocialMedial(payload, isMirumar);
            if (!isMirumar) {
                if (response?.data?.access_token) {
                    setCookieData(response?.data);
                    window.location.href = String(process.env.REACT_APP_HACOSCO_WEB_HOME_URL);
                } else {
                    toast('error', MESSAGE_ERROR_LOGIN_SOCIAL_MEDIA);
                    navigate('/');
                }
            } else {
                if (response?.redirect_url) {
                    window.location.href = `${HTTPS + urlRedirect + `?code=${response?.code}`}`;
                } else {
                    navigate('/' + `?service=mirumar&url=${urlRedirect}`);
                }
            }
        };
        handleFinishLoginTwitter();
    }, []);

    return (
        <>
            <div className="w-full h-[100vh] flex justify-center items-center">
                <Spin />
            </div>
        </>
    );
};
export default AuthLogin;
